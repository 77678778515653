import { render, staticRenderFns } from "./CreateSalutation.vue?vue&type=template&id=6011357d&scoped=true&"
import script from "./CreateSalutation.vue?vue&type=script&lang=js&"
export * from "./CreateSalutation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6011357d",
  null
  
)

export default component.exports